import actionTypes from "../actionTypes";

export const enviarContacto = (language, data) => {
	return {
		type: actionTypes.CONTACTO_INIT,
		payload: {
			language,
			data,
		},
	};
};
