import React, { useState, useEffect } from "react";
import Footer from "../components/footer";
import Layout from "../components/layout";
import SEO from "../components/seo";
import BodyClassName from "react-body-classname";
import { useIntl, Link, navigate } from "gatsby-plugin-intl";
import { connect } from "react-redux";
import { enviarContacto } from "../redux/contacto/actions";
import { appRedirect } from "../redux/app/actions";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import ReactLoading from "react-loading";
import Tools from "../classes/tools";

const Contacto = ({ enviarContacto, contactoLoading, contactoDone, appRedirect, s }) => {
	const intl = useIntl();

	const [nombre, setNombre] = useState("");
	const [email, setEmail] = useState("");
	const [message, setMessage] = useState("");

	useEffect(() => {
		if (contactoDone) {
			setNombre("");
			setEmail("");
			setMessage("");
			appRedirect({ to: "/" });
		}
	}, [contactoDone]);

	const handleSubmit = (e) => {
		e.preventDefault();
		enviarContacto(intl.locale, {
			nombre,
			email,
			message,
		});
	};

	const images = useStaticQuery(graphql`
		query {
			contact: file(relativePath: { eq: "contact.jpg" }) {
				childImageSharp {
					fluid {
						...GatsbyImageSharpFluid
					}
				}
			}
		}
	`);

	const onHeaderGeoLocationChange = (data) => {
		navigate(
			`/anuncios/mode/geo/lat/${data.lat}/long/${data.long}/geo_descripcion/${data.descripcion}/orden/distancia`
		);
	};

	return (
		<>
			<BodyClassName className="contact">
				<Layout onHeaderGeoLocationChange={onHeaderGeoLocationChange}>
					<SEO title={Tools.convertString(s.contacto?.title)} />
					<main className="page form-page">
						{/* STEP 01 */}
						<div className="steps-wrapper">
							<article className="form-box box">
								<ul className="breadcrumbs white-bg">
									<li className="link">
										<Link to="/" className="ltblue-text-02">
											Home
										</Link>
									</li>
									<li className="active">
										<p className="lightgray-text-07">{Tools.convertString(s.contacto?.title)}</p>
									</li>
								</ul>
								<div className="wrapper">
									<h4 className="black-text-01 prequel-font">{Tools.convertString(s.contacto?.title)}</h4>
									<p className="black-text-01">{Tools.convertString(s.contacto?.subtitle)}</p>
									<form onSubmit={handleSubmit}>
										<fieldset>
											<label className="black-text-01" htmlFor="name">
												<input
													className="input black-text-03"
													placeholder={Tools.convertString(s.contacto?.nombre)}
													name="name"
													id="name"
													type="text"
													value={nombre}
													onChange={(e) => setNombre(e.target.value)}
												/>
												<span className="black-text-01">{Tools.convertString(s.contacto?.nombre)}</span>
											</label>
											{/* OJO: aquí le puse "mail" en vez de "email", por un problema que nunca vi,
											al hacer focus al input el label no subia teniendo "email" */}
											<label className="black-text-01" htmlFor="mail">
												<input
													className="input black-text-03"
													placeholder={Tools.convertString(s.contacto?.email)}
													name="mail"
													id="mail"
													type="text"
													value={email}
													onChange={(e) => setEmail(e.target.value)}
												/>
												<span className="black-text-01">{Tools.convertString(s.contacto?.email)}</span>
											</label>
											<label className="black-text-01" htmlFor="message">
												<textarea
													className="black-text-03"
													placeholder={Tools.convertString(s.contacto?.mensaje)}
													name="message"
													id="message"
													value={message}
													onChange={(e) => setMessage(e.target.value)}
												/>
												<span className="black-text-01">{Tools.convertString(s.contacto?.mensaje)}</span>
											</label>
										</fieldset>
										<div className="button-wrapper">
											<button
												type="submit"
												className="button black-bg-03 white-text"
												disabled={contactoLoading}
											>
												{!contactoLoading && s.contacto?.enviar}
												{contactoLoading && (
													<ReactLoading type="spin" color="white" width={16} height={16} />
												)}
											</button>
										</div>
									</form>
								</div>
							</article>
							<article className="form-img show-lg">
								<picture>
									<Img fluid={images.contact.childImageSharp.fluid} alt={``} />
								</picture>
							</article>
						</div>
						{/* /STEP 01 */}
						<Footer />
					</main>
				</Layout>
			</BodyClassName>
		</>
	);
};

const mapStateToProps = (state) => {
	const { contactoLoading, contactoDone } = state.app.events;
	return {
		contactoLoading,
		contactoDone,
		s: state.app.boot.strings,
	};
};

const mapDispatchToProps = {
	enviarContacto,
	appRedirect,
};

export default connect(mapStateToProps, mapDispatchToProps)(Contacto);
